import Image from 'next/image'
import Link from 'next/link'
import { cn } from '~/lib/utils'

export const AppsFooter = (
	props: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLUListElement>,
		HTMLUListElement
	>,
) => (
	<ul
		{...props}
		className={cn(
			`flex flex-row justify-center gap-2 sm:flex-col`,
			props.className,
		)}
	>
		<li>
			<Link
				href='https://apps.apple.com/fr/app/cocoon-space/id1217025853'
				target='_blank'
			>
				<Image
					src='/images/v2/badges/apple_badge.png'
					alt='IOS'
					width={150}
					height={45}
				/>
			</Link>
		</li>

		<li>
			<Link
				href='https://play.google.com/store/apps/details?id=com.cocoonspaceapp'
				target='_blank'
			>
				<Image
					src='/images/v2/badges/google_badge.png'
					alt='Android'
					width={150}
					height={45}
				/>
			</Link>
		</li>
	</ul>
)
