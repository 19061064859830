import { useEffect, useRef } from 'react'

export type AxeptioEventName =
	| 'consent:saved'
	| 'cookies:complete'
	| 'cookies:step:change'
	| 'cookies:step.startTimeout'
	| 'cookies:step.stopTimeout'
	| 'token:update'
	| 'overlayOpenWidget'
	| 'overlayOpenCookies'
	| 'showProcessingDetails'
	| 'close'

type AxeptioProviders = 'mixpanel' | 'frontapp'

export type Choices = Record<AxeptioProviders, boolean | undefined>

export type Cb = (choices?: Choices) => void

interface AxeptioEventHandlers {
	on: (eventName: AxeptioEventName, cb: Cb) => void
}

type AxeptioArrayEl = (axeptio: AxeptioEventHandlers) => void

interface AxeptioSDK {
	push: (axeptio: AxeptioEventHandlers) => void
}

// https://developers.axeptio.eu/v/francais/sdk/integration-du-sdk
declare global {
	interface Window {
		axeptioSettings?: {
			clientId?: string
			tokenInstance?: unknown
			cookiesVersion?: string
			triggerGTMEvents?: boolean
			jsonCookieName?: string
			authorizedVendorsCookieName?: string
			allVendorsCookieName?: string
			userCookiesDuration?: number
			userCookiesDomain?: string
			userCookiesSameSite?: string
			userCookiesSecure?: boolean
			openCookiesWidgetIfVendorsMismatch?: boolean
			mountClassName?: string
			apiUrl?: string
		}
		openAxeptioCookies: VoidFunction
		_axcb?: AxeptioArrayEl[]
	}
}

export const useAxeptio = () => {
	const axeptioQueue = useRef<AxeptioArrayEl[]>([])

	if (typeof window === 'undefined') return

	window._axcb = window._axcb || []

	axeptioQueue.current = window._axcb

	return axeptioQueue.current
}

/** ⚠️ Always load this component with dynamic(() => 'xxx', { ssr: false }) as it's leveraging on document */
const AxeptioCookiesBanner = () => {
	useEffect(() => {
		if (typeof document === 'undefined') return

		// Prevent adding multiple script tags
		if (document.getElementById('axeptio-script')) return

		window.axeptioSettings = {
			clientId: process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID,
			mountClassName: 'pointer-events-auto',
		}

		const script = document.createElement('script')

		script.id = 'axeptio-script'
		script.type = 'text/javascript'
		script.src = '//static.axept.io/sdk-slim.js'
		// script.src = '//static.axept.io/sdk.js'
		script.async = true

		document.body.appendChild(script)
	}, [])

	return null
}

export default AxeptioCookiesBanner
