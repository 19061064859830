import {
	Hydrate,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { PropsWithChildren } from 'react'
import { analytics } from '~/lib/analytics'
import { TrackingProvider } from '~/providers/tracking-provider'
import { UserProvider } from '~/providers/user-provider'
import { TooltipProvider } from './ui/tooltip'

const queryClient = new QueryClient()

export const Providers = ({
	children,
	pageProps,
}: PropsWithChildren<{ pageProps: any }>) => (
	<QueryClientProvider client={queryClient}>
		<Hydrate state={pageProps.dehydratedState}>
			<TrackingProvider instance={analytics}>
				<UserProvider>
					<TooltipProvider delayDuration={300}>{children}</TooltipProvider>
				</UserProvider>
			</TrackingProvider>
		</Hydrate>

		<ReactQueryDevtools initialIsOpen={false} />
	</QueryClientProvider>
)
