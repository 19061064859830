import { Star } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { cn } from '~/lib/utils'

interface RatingProps {
	defaultValue: number
	className?: string
}

export const Rating = ({ defaultValue, className }: RatingProps) => {
	const valueRoundedToLowerHalf = Math.floor(defaultValue * 2) / 2

	return (
		<div className={cn('flex gap-x-0.5', className)}>
			{Array.from({ length: 5 }, (_, i) => {
				const diff = Math.min(Math.abs(i - valueRoundedToLowerHalf), 1)

				return (
					<div key={i} className='relative'>
						<div
							style={{
								width: i < defaultValue ? `${100 * diff}%` : 0,
							}}
							className='absolute overflow-hidden'
						>
							<Star className='fill-primary stroke-primary' strokeWidth={0} />
						</div>

						<Star className='fill-gray-600' strokeWidth={0} />
					</div>
				)
			})}
		</div>
	)
}

export const BaselineAndRating = () => {
	const { t } = useTranslation()

	return (
		<div className='mb-12 flex flex-col justify-between gap-4 md:flex-row md:items-end'>
			<div>
				<p className='mb-1 font-bold text-4xl text-primary'>cocoon space</p>

				<p>{t('cwFooter:baseline')}</p>
			</div>

			<div className='flex items-center'>
				4,8
				<Rating className='mx-2' defaultValue={4.8} />
				<a
					href='https://www.google.com/maps/search/cocoon+space/@48.8665006,2.306261,13z?entry=ttu'
					target='_blank'
					className='text-gray-800'
				>
					449 avis
				</a>
			</div>
		</div>
	)
}
