import type { Api } from '@cocoonspace/types/shared/api'
import type { StripeCardElement } from '@stripe/stripe-js'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { usePayment } from '../../../../apps/cocoon-web/src/domains/payments/providers/payment.provider'
import { useAuth } from '../../domains/auth/hooks/use-auth.hook'
import { cleanQueryKeyFactories } from '../../utils/clean-query-key-factories'
import { useCocoonApi } from './use-cocoon-api.hook'

export namespace PaymentMethodHooksCtx {
	export interface list {}
}

export type PaymentCreatePayload = {
	card: StripeCardElement
	name: string
	title: string
} & Api.Schemas['AccountPaymentMethodAddRequest']

/**
 * Query keys
 */
export const PAYMENT_METHOD_KEYS = cleanQueryKeyFactories({
	base: ['paymentmethods'] as const,
	list: () => [...PAYMENT_METHOD_KEYS.base, 'list'] as const,
} as const)

export const usePaymentMethodList = () => {
	const { isLoading, isAuth } = useAuth()
	const { getAll } = useCocoonApi('paymentMethods')

	const query = useQuery({
		queryKey: PAYMENT_METHOD_KEYS.list(),
		queryFn: () => getAll().then((res) => res.data.data),
		enabled: !isLoading && isAuth,
	})

	const defaultPaymentMethod = query.data?.find((pm) => pm.default)

	return { ...query, defaultPaymentMethod }
}

export const usePaymentMethodCreate = () => {
	const { createPaymentMethod } = usePayment()
	const queryClient = useQueryClient()
	const { createOne } = useCocoonApi('paymentMethods')

	return useMutation({
		mutationFn: async ({ card, name, title, type }: PaymentCreatePayload) => {
			const res = await createOne({ data: { type } }).then(
				(res) => res.data.data,
			)

			if (res?.continuation_key) {
				return createPaymentMethod(res.continuation_key, {
					metadata: { title },
					billingDetails: { name },
					cardElement: card,
				})
			}
		},
		onSuccess: () => {
			queryClient.refetchQueries(PAYMENT_METHOD_KEYS.list())
		},
	})
}

export const useIsPaymentMethodExpired = (pm: Api.AccountPaymentMethod) => {
	if (!pm.card?.exp_year || !pm.card?.exp_month) {
		return false
	}

	const today = new Date()
	const cardExpirationDate = new Date()

	cardExpirationDate.setFullYear(pm.card.exp_year, pm.card.exp_month, 1)

	return cardExpirationDate < today
}
