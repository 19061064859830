import { SvgFacebookF } from '@cocoonspace/icons/react/font-awesome/brands/facebook-f'
import { SvgInstagram } from '@cocoonspace/icons/react/font-awesome/brands/instagram'
import { SvgLinkedinIn } from '@cocoonspace/icons/react/font-awesome/brands/linkedin-in'
import { SvgXTwitter } from '@cocoonspace/icons/react/font-awesome/brands/x-twitter'
import Link from 'next/link'

const links = [
	{
		icon: SvgFacebookF,
		title: 'Facebook',
		href: 'https://www.facebook.com/mycocoonspace',
	},
	{
		icon: SvgInstagram,
		title: 'Instagram',
		href: 'https://www.instagram.com/cocoonspace/?hl=fr',
	},
	{
		icon: SvgXTwitter,
		title: 'X',
		href: 'https://x.com/cocoon_space',
	},
	{
		icon: SvgLinkedinIn,
		title: 'LinkedIn',
		href: 'https://fr.linkedin.com/company/cocoon-space',
	},
]

export const SocialFooter = () => {
	return (
		<div className='flex flex-row items-center gap-8'>
			{links.map((link) => (
				<Link
					key={link.title}
					href={link.href}
					target='_blank'
					title={link.title}
					className='hover:text-primary'
				>
					<link.icon className='text-3xl md:text-base' />
				</Link>
			))}
		</div>
	)
}
