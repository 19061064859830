import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from '~/components/ui/dialog'

import { cn } from '~/lib/utils'
import { ForgottenPassForm } from './forgotten-pass-form'
import { LoginForm } from './login-form'
import { RegisterForm } from './register-form'

export const AuthDialog = () => {
	const { t } = useTranslation()
	const { isAuth } = useAuth()

	const {
		isAuthDialogOpen,
		activeTab,
		errorMsg,
		computed: { title },
		openDialog,
		closeDialog,
	} = useAuthDialogStore()

	useEffect(() => {
		if (isAuth) {
			closeDialog()
		}
	}, [isAuth, closeDialog])

	return (
		<Dialog
			open={isAuthDialogOpen}
			onOpenChange={(isOpen) => (isOpen ? openDialog() : closeDialog())}
		>
			<DialogContent
				className={cn(
					'max-w-[385px] pb-3',
					['login', 'register'].includes(activeTab) && 'px-0',
				)}
				hideCloseBtn
			>
				<DialogHeader
					className={cn(['login', 'register'].includes(activeTab) && 'px-6')}
				>
					<DialogTitle>{t(title)}</DialogTitle>
				</DialogHeader>

				{!!errorMsg && (
					<p className='px-6 mb-4 text-primary text-sm'>{errorMsg}</p>
				)}

				{activeTab === 'login' && <LoginForm />}

				{activeTab === 'register' && <RegisterForm />}

				{activeTab === 'forgottenPass' && <ForgottenPassForm />}
			</DialogContent>
		</Dialog>
	)
}
